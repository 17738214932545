<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    :class="classname"
  >
    <path d="M15.6216 7.15437L12.6613 4.19407C12.3481 3.88097 11.9234 3.70508 11.4806 3.70508C11.0377 3.70508 10.613 3.88097 10.2998 4.19407L1.16274 13.3312C0.800447 13.6775 0.510993 14.0927 0.311367 14.5524C0.11174 15.0121 0.00596302 15.5071 0.000244928 16.0083C-0.00547316 16.5094 0.0889826 17.0067 0.278068 17.4708C0.467153 17.935 0.747058 18.3567 1.10135 18.7112C1.45564 19.0657 1.87718 19.3458 2.34123 19.5352C2.80527 19.7245 3.30247 19.8193 3.80363 19.8139C4.30479 19.8084 4.79982 19.7029 5.25965 19.5036C5.71949 19.3042 6.13486 19.015 6.48139 18.6529L15.6185 9.51582C15.932 9.20305 16.1085 8.77857 16.109 8.33573C16.1096 7.89289 15.9343 7.46795 15.6216 7.15437ZM5.17257 17.341C4.81398 17.6996 4.32764 17.901 3.82052 17.901C3.31341 17.901 2.82706 17.6996 2.46848 17.341C2.10989 16.9824 1.90844 16.4961 1.90844 15.989C1.90844 15.4818 2.10989 14.9955 2.46848 14.6369L7.63279 9.47569C7.66381 9.52197 7.69893 9.56536 7.73775 9.60533L10.2072 12.0748C10.2472 12.1136 10.2906 12.1488 10.3369 12.1798L5.17257 17.341ZM11.621 10.8956C11.59 10.8494 11.5549 10.806 11.5161 10.766L9.04657 8.29651C9.0066 8.25769 8.96321 8.22257 8.91693 8.19155L11.479 5.62946L14.1831 8.33355L11.621 10.8956Z" />
    <path d="M17.344 0L16.4704 1.59591L14.8745 2.46949L16.4704 3.34307L17.344 4.93897L18.2176 3.34307L19.8135 2.46949L18.2176 1.59591L17.344 0Z" />
    <path d="M17.3434 11.7324L16.581 13.1277L15.1826 13.8932L16.581 14.6557L17.3434 16.054L18.1059 14.6557L19.5042 13.8932L18.1059 13.1277L17.3434 11.7324Z" />
    <path d="M5.46606 1.70581L4.0708 2.47135L5.46606 3.23689L6.2316 4.63215L6.99714 3.23689L8.3924 2.47135L6.99714 1.70581L6.2316 0.310547L5.46606 1.70581Z" />
  </svg>
</template>

<script>
export default {
  name: 'AutoPilot',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
