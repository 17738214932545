<template>
  <div
    class="
      relative
      flex
      items-center
      justify-center
      audio-controls
      controlsContainer
    "
    :class="{ 'overflow-hidden': !touring }"
  >
    <div
      v-show="editMode === 'editMedia'"
      @click="toggleDeleted"
      v-tooltip.top="`${showDeleted ? 'Hide Deleted' : 'Show Deleted'}`"
      class="mr-4 cursor-pointer text-primary-500 hover:text-primary-700"
    >
      <EyeOpen v-if="showDeleted" />
      <EyeOff v-else />
    </div>

    <div
      v-show="editMode !== 'editMedia'"
      class="mr-4 cursor-not-allowed text-primary-500"
    >
      <EyeOpen />
    </div>

    <div
      v-show="!isOnboarding"
      class="mr-4 cursor-pointer text-primary-500 hover:text-primary-700"
      @click="backward"
    >
      <PlayPrev />
    </div>

    <div
      v-tooltip="{
        content: `<div class='flex items-center text-white text-2xs'>
            <div>Play/Pause</div>
            <div class='px-2 py-1 ml-3 rounded-sm bg-primary-800 text-2xs'>Spacebar</div>
          </div>
        </template>`,
        show: false,
        offset: 8,
        trigger: (isDemo || isOnboarding) ? 'manual' : 'hover',
        placement: 'top',
      }"
      id="v-step-2"
    >
      <div
        v-if="isPlaying"
        class="cursor-pointer text-primary hover:text-red"
        @click="toggleState"
      >
        <Pause />
      </div>
      <!--      <div-->
      <!--        v-else-if="isPlayerGettingReady"-->
      <!--        class="text-primary hover:text-red w-6"-->
      <!--      >-->
      <!--        <CircularLoader />-->
      <!--      </div>-->
      <div
        v-else
        class="cursor-pointer text-primary hover:text-red"
        @click="toggleState"
      >
        <Play />
      </div>
    </div>

    <div
      v-show="!isOnboarding"
      class="ml-4 mr-4 cursor-pointer text-primary-500 hover:text-primary-700"
      @click="forward"
    >
      <PlayNext />
    </div>
    <div v-if="isDemo || isOnboarding" id="reduceNoiseButton">
      <div
        class="cursor-pointer py-s4"
        v-tooltip="{
          content: `Click to turn noise reduction ${
            reduceNoise ? 'off' : 'on'
          }`,
          offset: 8,
          placement: 'top',
        }"
        @click="toggleReduceNoise"
      >
        <NoiseIcon
          class="transition"
          :class="{
            'text-green hover:text-green-700': reduceNoise,
            'text-primary-400 hover:text-primary-500': !reduceNoise,
            'ml-4': isOnboarding
          }"
        />
      </div>
    </div>
    <SmartEdit
    v-if="!isOnboarding"
      id="v-step-1"
      :showAutopilotTooltip="showAutopilotTooltip"
      :removeAutopilotTooltip="removeAutopilotTooltip"
    />

    <div
      v-if="(!isDemo && !isOnboarding) && (audio_editor || showMidMenu)"
      id="v-step-0"
      class="relative flex items-center justify-center ml-4 h-9 w-9"
    >
      <div
        v-if="!touring"
        class="
          absolute
          w-1
          h-1
          transition-all
          duration-500
          ease-in-out
          bg-opacity-0
          rounded-full
          bg-primary
        "
        :class="{
          'bg-opacity-100 w-screen h-screen': isTranscriptMode,
        }"
      />
      <div
        class="
          absolute
          text-xs text-white
          transition-all
          duration-500
          ease-in-out
          opacity-0
          w-0
          right-14
          rounded
          text-center
          whitespace-nowrap
          transcript-msg-bg
        "
        :class="{
          'opacity-100 transcript-msg-width px-2 py-1': isTranscriptMode,
        }"
      >
        You are currently correcting the transcript.
      </div>
      <IconButton
        :classname="`transition-all duration-500 ease-in-out z-10 relative group ${
          isTranscriptMode ? 'bg-red-100' : 'bg-primary-200'
        }`"
        v-tooltip.right="
          isTranscriptMode
            ? 'Toggle to edit media'
            : 'Toggle to correct transcript'
        "
        @click="toggleTranscriptMode"
        :variant="switching ? 'disabled' : 'normal'"
        :class="{ grayBorder: isTranscriptMode }"
      >
        <div>
          <div
            class="
              absolute
              flex
              items-center
              justify-center
              w-5
              h-5
              rounded-full
              -bottom-2
              -right-2
            "
            :class="isTranscriptMode ? 'bg-primary-900' : 'bg-white'"
          >
            <svg
              height="20"
              width="20"
              class="loadingCircle"
              fill="none"
              v-show="switching"
            >
              <circle cx="10" cy="10" r="9" class="circleStroke" />
            </svg>
            <div
              class="
                absolute
                flex
                items-center
                justify-center
                w-4
                h-4
                rounded-full
              "
              :class="
                isTranscriptMode ? 'bg-red' : 'bg-primary group-hover:bg-red'
              "
            >
              <div class="relative font-bold text-white text-2xs">
                {{ isTranscriptMode ? "T" : "M" }}
              </div>
            </div>
          </div>

          <EditIcon
            class="w-5 h-5"
            :class="isTranscriptMode ? 'text-red' : 'text-primary'"
          />
        </div>
      </IconButton>
    </div>
    <div v-else-if="!isDemo && !isOnboarding" class="switchToEditMedia">
      <Button @click="openPlansModal" variant="secondary">Switch To Editing Media</Button>
      <Button @click="openPlansModal" variant="secondary" v-tooltip.top="'Switch To Editing Media'">
        <EditIcon
          class="w-5 h-5"
          :class="isTranscriptMode ? 'text-red' : 'text-primary'"
      /></Button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import store from '@/services/store'
import EyeOff from '@/components/base/icons/EyeOff.vue'
import EyeOpen from '@/components/base/icons/EyeOpen.vue'
import PlayPrev from '@/components/base/icons/PlayPrev.vue'
import Play from '@/components/base/icons/Play.vue'
import Pause from '@/components/base/icons/Pause.vue'
import NoiseIcon from '@/components/base/icons/Noise.vue'
import PlayNext from '@/components/base/icons/PlayNext.vue'
import EditIcon from '@/components/base/icons/Edit.vue'
import IconButton from '@/components/base/buttons/IconButton'
import Button from '@/components/base/buttons/Button.vue'
import {
  myProseEditor,
  setPlayingSelectionHighlight,
  toggleTranscriptMode
} from '@/view/voiceEditor/proseEditor/util/utility'

import SmartEdit from '../../SmartEdit.vue'
// import CircularLoader from '@/components/base/icons/CircularLoader'

export default {
  name: 'AudioControls',
  components: {
    // CircularLoader,
    EyeOff,
    EyeOpen,
    PlayPrev,
    Play,
    Pause,
    PlayNext,
    NoiseIcon,
    EditIcon,
    IconButton,
    SmartEdit,
    Button
  },
  data: function () {
    return {
      showMidMenu: false
    }
  },
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
    reduceNoise: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    showDeleted: {
      type: Boolean,
      default: false
    },
    toggleDeleted: {
      type: Function,
      default: () => null
    },
    editMode: {
      type: String,
      required: true
    },
    showAutopilotTooltip: {
      type: Boolean,
      default: false
    },
    removeAutopilotTooltip: {
      type: Function,
      default: () => null
    },
    touring: {
      type: Boolean,
      default: false
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal'
    }),
    toggleState() {
      let vm = this
      vm.$emit('toggleState')
    },
    backward: function () {
      let vm = this
      vm.$emit('backward')
    },
    forward: function () {
      let vm = this
      vm.$emit('forward')
    },
    toggleReduceNoise: function () {
      let vm = this
      vm.$emit('toggleReduceNoise')
    },
    toggleTranscriptMode: () => {
      toggleTranscriptMode()
    },
    openPlansModal: function() {
      this.openModal({ name: 'AccountInfo', props: { tab: 'Plans' } })
    }
  },
  computed: {
    ...mapGetters({
      isDemo: 'editor/isDemo',
      switching: 'editor/switching',
      isTranscriptMode: 'editor/isTranscriptMode',
      podcastInfo: 'editor/podcastInfo',
      freshDoc: 'editor/freshDoc',
      isPlayerGettingReady: 'editor/isPlayerGettingReady'
    }),
    audio_editor() {
      if (this.podcastInfo && this.podcastInfo.audio_editor) {
        return this.podcastInfo.audio_editor
      }
      return true
    }
  },
  watch: {
    freshDoc(val) {
      if (!val) this.showMidMenu = true
    }
  }
}
</script>

<style lang="scss" scoped>
.controlsContainer {
  height: 56px;
  margin-top: -24px;
  padding-bottom: 2px;
}

.trigger {
  display: block;
}
.editTranscriptButtonGlow {
  background: black;
  border-radius: 6px;
  box-shadow: rgb(0 140 255 / 30%) 0px 0px 10px 10px;
  color: white;
}

.grayBorder {
  border: 2px solid #1d2736;
}

.switchToEditMedia {
  position: absolute;
  right: 70px;
  bottom: 8px;
  button:last-child {
    display: none;
  }
  @media (max-width: 1200px) {
    button:first-child {
      display: none;
    }

    button:last-child {
      display: block;
    }
  }
}

.transcript-msg-bg {
 background: #0F112D;
}

.transcript-msg-width {
  width: 16rem;
}
</style>
