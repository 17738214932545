<template>
  <div v-if="!isDemo">
    <div
      v-if="showAutopilotTooltip"
      v-tooltip="{
        content: 'Autopilot has taken care of the boring stuff already 😄',
        show: true,
        trigger: 'manual',
        placement: 'top'
      }"
      class="cursor-pointer text-red"
      @click="
        removeAutopilotTooltip();
        togglePopover();
      "
    >
      <AutoPilotIcon />
    </div>
    <v-popover
      v-else
      offset="4"
      :open="popoverOpen"
      placement="top"
      @show="onShow"
      @hide="onHide"
    >
      <div
        v-tooltip.top="popoverOpen ? '' : 'Autopilot'"
        class="cursor-pointer"
        :class="[
          popoverOpen ? 'text-red' : 'text-primary-500 hover:text-primary-700'
        ]"
        @click="togglePopover"
      >
        <AutoPilotIcon />
      </div>

      <template slot="popover">
        <div class="p-6 block smarteditPopup">
          <div class="flex items-center justify-between">
            <div class="font-h font-semibold text-primary text-2xl">
              Autopilot
            </div>

            <div
              class="cursor-pointer text-primary ml-1"
              v-close-popover
              @click="openSmartEditModal()"
            >
              <SettingsIcon class="settingIcon" />
            </div>
          </div>
          <div class="mt-1 text-sm text-primary-600">
            Automatic editing to make your life easier 😉
          </div>

          <div class="border-t border-primary-300 mt-4 w-full" />

          <div class="flex mt-4 pt-0.5 items-center justify-between">
            <div>
              <div class="flex mb-1 items-center">
                <div class="font-bold text-sm text-primary">
                  Filler word Removal
                </div>
                <div
                  class="cursor-pointer text-xs ml-1 text-primary-500 hover:text-blue"
                  v-close-popover
                  @click="openSmartEditModal({ isAddingFillerWord: true })"
                  v-tooltip.right="'Define words to remove'"
                >
                  <EditIcon class="h-5 w-5" />
                </div>
              </div>

              <div class="text-xs text-primary-600">
                <span
                  v-if="fillerWords.count === 0"
                  class="text-blue"
                  >
                  {{fillerWords.count}} fillers
                </span>
                <span
                  v-else
                  class="cursor-pointer text-blue underline"
                  v-close-popover
                  @click="openPanel('fillerWords')"
                >
                  {{ fillerWords.count }} fillers
                </span>
                {{
                  fillerWords.isEnabled
                    ? "deleted. No more umms & uhhs!"
                    : "can be deleted. Say no to umms & uhhs!"
                }}
              </div>
            </div>
            <VueSwitch v-model="isFillerwordsEnabled" />
          </div>

          <div class="flex mt-3 pt-0.5 items-center justify-between">
            <div>
              <div class="font-bold text-sm text-primary mb-1">
                Silence Trimmer
              </div>
              <div class="text-xs text-primary-600">
                {{ trimSilence.isEnabled ? "Trimmed " : "" }}
                <span
                  v-if="trimSilence.formatedDuration === '0 secs'"
                  class="text-blue"
                >
                  {{trimSilence.formatedDuration}}
                </span>
                <span
                  v-else
                  v-close-popover
                  @click="openPanel('silence')"
                  class="cursor-pointer text-blue underline"
                >
                  {{ trimSilence.formatedDuration }}
                </span>
                {{
                  trimSilence.isEnabled
                    ? "of awkward silences"
                    : "of awkward silences can be trimmed"
                }}
              </div>
            </div>
            <VueSwitch v-model="isTrimSilenceEnabled" />
          </div>

          <div class="flex mt-3 pt-0.5 items-center justify-between">
            <div>
              <div class="font-bold text-sm text-primary mb-1">
                Noise Reduction
              </div>
              <div class="text-xs text-primary-600 w-64">
                Background noise reduction & voice leveling will be applied when
                you export
              </div>
            </div>
            <VueSwitch v-model="isNoiseReductionIsEnabled" />
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>
<script>
import sum from 'lodash/sum'
import { mapActions, mapGetters } from 'vuex'
import AutoPilotIcon from '@/components/base/icons/AutoPilot.vue'
import VueSwitch from '@/components/base/inputs/Switch'
import SettingsIcon from '@/components/base/icons/Settings.vue'
import EditIcon from '@/components/base/icons/Edit.vue'

import {
  searchFillerWordInDoc,
  searchContinuousSilence
} from '@/view/voiceEditor/proseEditor/util/smartEditUtilities'
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../../../proseEditor/util/editorSearchUtilityWorker'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
import store from '@/services/store'
let editorSearchUtilityWorkerInstance = worker()
const formatSeconds = durationInSeconds => {
  const minutes = Math.floor(Math.round(durationInSeconds) / 60)
  const seconds = Math.round(durationInSeconds) - minutes * 60

  return `${minutes ? (minutes + 'm ') : ''}${seconds} secs`
}

export default {
  name: 'SmartEdit',
  components: {
    VueSwitch,
    AutoPilotIcon,
    SettingsIcon,
    EditIcon
  },
  data () {
    return {
      fillerWordCount: 0,
      trimDuration: '0 secs',
      popoverOpen: false
    }
  },
  props: {
    showAutopilotTooltip: {
      type: Boolean,
      default: false
    },
    removeAutopilotTooltip: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    ...mapGetters({
      'fillerWords': 'smartEditConfig/fillerWords',
      'allFillerWords': 'smartEditConfig/allFillerWords',
      'silenceUpto': 'smartEditConfig/silenceUpto',
      'trimSilence': 'smartEditConfig/trimSilence',
      isNoiseReductionEnabled: 'smartEditConfig/isNoiseReductionEnabled',
      nrConfig: 'smartEditConfig/nrConfig',
      isProseEditorInitialised: 'editor/isProseEditorInitialised',
      isDemo: 'editor/isDemo'
    }),
    isFillerwordsEnabled: {
      get: function() {
        return this.fillerWords.isEnabled
      },
      set: function(val) {
        store.commit('smartEditConfig/setFillerWordIsEnabled', val)
        store.dispatch('editor/setEditorBlocked', { value: true, reason: 'Autopilot Processing...' })
        editorSearchUtilityWorkerInstance.multipleSearchInDocWorker(myProseEditor.view.state.doc.toJSON(), store.state.smartEditConfig.fillerWords.words).then(
          result => {
            let finalResult = result.filter(u => !u.isRestored)
            store.commit('smartEditConfig/setFillerWordCount', result.length)
            this.setFillerWordsIsEnabled({
              ownerId: this.$route.params.userId,
              podId: this.$route.params.podId,
              isEnabled: val,
              searchResult: finalResult
            })
            store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
          }
        )
      }
    },
    isTrimSilenceEnabled: {
      get: function() {
        return this.trimSilence.isEnabled
      },
      set: function(val) {
        store.commit('smartEditConfig/setTrimSilenceIsEnabled', val)
        store.dispatch('editor/setEditorBlocked', { value: true, reason: 'Autopilot Processing...' })
        if (val) {
          editorSearchUtilityWorkerInstance.searchContinuousSilence(myProseEditor.view.state.doc.toJSON(), store.state.smartEditConfig.silenceUpto).then(
            result => {
              this.setTrimSilenceIsEnabled({
                ownerId: this.$route.params.userId,
                podId: this.$route.params.podId,
                isEnabled: val,
                searchResult: result
              })
              store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
            }
          )
        } else {
          editorSearchUtilityWorkerInstance.searchSmartEditAppliedSilence(myProseEditor.view.state.doc.toJSON()).then(
            result => {
              this.setTrimSilenceIsEnabled({
                ownerId: this.$route.params.userId,
                podId: this.$route.params.podId,
                isEnabled: val,
                searchResult: result
              })

              store.dispatch('editor/setEditorBlocked', { value: true, reason: 'Autopilot Processing...' })
              editorSearchUtilityWorkerInstance.searchContinuousSilence(myProseEditor.view.state.doc.toJSON(), store.state.smartEditConfig.silenceUpto).then(
                result => {
                  store.commit('smartEditConfig/setTrimmedSilenceDurationInMs', result.reduce((a, b) => {
                    // console.log(a, b)
                    return a + b.meta.silenceDurationInSeconds
                  }, 0))
                  store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
                }
              )

              // store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
            }
          )
        }
      }
    },
    isNoiseReductionIsEnabled: {
      get: function() {
        return this.isNoiseReductionEnabled
      },
      set: function(val) {
        this.setNoiseReductionIsEnabled({
          ownerId: this.$route.params.userId,
          podId: this.$route.params.podId,
          isEnabled: val,
          all: true
        })
      }
    }
  },
  methods: {
    ...mapActions({
      setFillerWordsIsEnabled: 'smartEditConfig/setFillerWordsIsEnabled',
      setTrimSilenceIsEnabled: 'smartEditConfig/setTrimSilenceIsEnabled',
      setNoiseReductionIsEnabled: 'smartEditConfig/setNoiseReductionIsEnabled',
      openModal: 'dialogs/openModal',
      togglePanel: 'editor/togglePanel',
      reAutopilot: 'smartEditConfig/reAutopilot',
      setEditorBlocked: 'editor/setEditorBlocked'
    }),
    openPanel: function(panel) {
      if (
        (panel === 'silence' && this.trimSilence.formatedDuration !== '0 secs') ||
        (panel === 'fillerWords' && this.fillerWords.count !== 0)
      ) {
        this.togglePanel({ panel })
      }
    },
    openSmartEditModal: function(modalProps = {}) {
      this.openModal({ name: 'SmartEditModal', props: modalProps })
    },
    updateMeta: function() {
      this.fillerWordCount = searchFillerWordInDoc().length
      this.trimDuration = formatSeconds(
        sum(
          searchContinuousSilence().map(t =>
            t.meta && t.meta.silenceDurationInSeconds
              ? t.meta.silenceDurationInSeconds
              : 0
          )
        )
      )
    },
    togglePopover: function() {
      this.popoverOpen = !this.popoverOpen
    },
    onShow: function() {
      this.setEditorBlocked({ value: true })
      this.popoverOpen = true
      if (!this.isFillerwordsEnabled) {
        store.dispatch('editor/setEditorBlocked', { value: true, reason: 'Autopilot Processing...' })
        editorSearchUtilityWorkerInstance.multipleSearchInDocWorker(myProseEditor.view.state.doc.toJSON(), store.state.smartEditConfig.fillerWords.words).then(
          result => {
            let finalResult = result.filter(u => !u.isRestored)
            store.commit('smartEditConfig/setFillerWordCount', finalResult.length)
            store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
          }
        )
      }
      if (!this.isTrimSilenceEnabled) {
        store.dispatch('editor/setEditorBlocked', { value: true, reason: 'Autopilot Processing...' })
        editorSearchUtilityWorkerInstance.searchContinuousSilence(myProseEditor.view.state.doc.toJSON(), store.state.smartEditConfig.silenceUpto).then(
          result => {
            store.commit('smartEditConfig/setTrimmedSilenceDurationInMs', result.reduce((a, b) => {
              // console.log(a, b)
              return a + b.meta.silenceDurationInSeconds
            }, 0))
            store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
          }
        )
      }
      // this.updateMeta()
    },
    onHide: function() {
      this.setEditorBlocked({ value: false })
      this.popoverOpen = false
    }
  },
  watch: {
    isProseEditorInitialised: function(val, oldVal) {
      if (val !== oldVal) this.updateMeta()
    }
  }
}
</script>
<style scoped>
.settingIcon {
  height: 26px;
  width: 26px;
}
</style>
