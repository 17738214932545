<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path d="M5.97037 5.9707C3.91598 7.51605 2.78448 9.58428 2.26959 10.7408C1.91113 11.546 1.91164 12.4557 2.26991 13.2603C3.13924 15.2126 5.89136 20.0002 12.0001 20.0002C14.5416 20.0002 16.5259 19.1608 18.0295 18.0299L16.5977 16.5981C15.4348 17.4109 13.9343 18.0002 12.0001 18.0002C7.14489 18.0002 4.88995 14.2276 4.09697 12.4468C3.96903 12.1594 3.96902 11.841 4.0967 11.5542C4.55759 10.519 5.55471 8.73224 7.30832 7.46911C7.33958 7.4466 7.36914 7.42262 7.397 7.39733L5.97037 5.9707Z" />
    <path d="M9.1716 9.17188C8.44888 9.8947 8 10.8957 8 12.0003C8 14.2094 9.79086 16.0003 12 16.0003C13.1047 16.0003 14.1056 15.5514 14.8284 14.8287L13.4142 13.4145C13.0513 13.7776 12.5522 14.0003 12 14.0003C10.8954 14.0003 10 13.1049 10 12.0003C10 11.4481 10.2227 10.949 10.5858 10.5861L9.1716 9.17188Z" />
    <path d="M12.0003 4C18.1092 4 20.8611 8.78782 21.7302 10.7401C22.0885 11.5448 22.0885 12.4552 21.7302 13.2599C21.5746 13.6096 21.3658 14.0352 21.0963 14.5009C20.8197 14.9789 20.208 15.1421 19.7299 14.8655C19.2519 14.5889 19.0887 13.9772 19.3653 13.4991C19.5948 13.1025 19.7722 12.7407 19.9031 12.4465C20.0309 12.1595 20.0309 11.8405 19.9031 11.5535C19.1103 9.77274 16.8556 6 12.0003 6L11 6.00001C10.4477 6.00001 10 5.5523 10 5.00002C9.99999 4.44773 10.4477 4.00001 11 4.00001L12.0003 4Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z" />
  </svg>
</template>

<script>
export default {
  name: 'EyeOff',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
