<template>
  <svg
    width="21"
    height="15"
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classname"
  >
    <path
      d="M1 6.62687L2.98352 10.3134L4.34066 6.62687L6.32418 12.0597L7.89011 2.55224L9.56044 14L11.4396 1L13.1099 11.0896L15.0934 5.46269L16.9725 10.3134L18.5 6.5L20 9.5"
      stroke="#53B745"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'AutoPilot',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
path {
  stroke: currentColor;
}
</style>
