<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path d="M4 21C4 20.4477 4.44772 20 5 20H19C19.5523 20 20 20.4477 20 21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4118 3.17352C13.9739 1.61143 16.5066 1.61143 18.0687 3.17352L18.826 3.93088C20.3881 5.49298 20.3881 8.02564 18.826 9.58774L11.9199 16.4938C11.6425 16.7712 11.2896 16.9609 10.9051 17.0393L6.40157 17.9572C4.99701 18.2435 3.75618 17.0027 4.04242 15.5981L4.96022 11.0944C5.03857 10.7099 5.22828 10.357 5.50573 10.0796L12.4118 3.17352ZM16.6545 4.58774C15.8734 3.80669 14.6071 3.80669 13.826 4.58773L13.4144 4.99938L17.0002 8.58516L17.4118 8.17353C18.1929 7.39248 18.1929 6.12614 17.4118 5.34509L16.6545 4.58774ZM8.41437 9.99936L12.0002 6.41359L15.586 9.99937L12.0002 13.5852L8.41437 9.99936ZM7.00015 11.4136L6.91994 11.4938L6.00214 15.9975L10.5057 15.0796L10.5859 14.9994L7.00015 11.4136Z" />
  </svg>
</template>

<script>
export default {
  name: 'Edit',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
