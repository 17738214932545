<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0001 7.00601C22.0001 4.33404 18.7702 2.99521 16.8799 4.8836L11.8798 9.87859C10.7067 11.0504 10.7067 12.9516 11.8798 14.1234L16.8799 19.1184C18.7702 21.0068 22.0001 19.668 22.0001 16.996V7.00601ZM18.2934 6.29854C18.9235 5.66908 20.0001 6.11536 20.0001 7.00601V16.996C20.0001 17.8866 18.9235 18.3329 18.2934 17.7035L13.2933 12.7085C12.9022 12.3179 12.9022 11.6841 13.2933 11.2935L18.2934 6.29854Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0001 7.00601C13.0001 4.33404 9.77023 2.99521 7.87989 4.8836L2.87977 9.87859C1.70674 11.0504 1.70674 12.9516 2.87977 14.1234L7.87989 19.1184C9.77022 21.0068 13.0001 19.668 13.0001 16.996V7.00601ZM9.29338 6.29854C9.92349 5.66908 11.0001 6.11536 11.0001 7.00601V16.996C11.0001 17.8866 9.92349 18.3329 9.29338 17.7035L4.29326 12.7085C3.90225 12.3179 3.90225 11.6841 4.29326 11.2935L9.29338 6.29854Z" />
  </svg>
</template>

<script>
export default {
  name: 'PlayPrev',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
