<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7.00406C2 4.33209 5.22989 2.99325 7.12023 4.88165L12.1203 9.87664C13.2934 11.0485 13.2934 12.9496 12.1204 14.1215L7.12023 19.1164C5.2299 21.0048 2 19.666 2 16.994V7.00406ZM5.70674 6.29659C5.07663 5.66712 4 6.1134 4 7.00406V16.994C4 17.8847 5.07663 18.331 5.70674 17.7015L10.7069 12.7065C11.0979 12.3159 11.0979 11.6822 10.7069 11.2916L5.70674 6.29659Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 7.00601C11 4.33404 14.2299 2.99521 16.1202 4.8836L21.1203 9.87859C22.2934 11.0504 22.2934 12.9516 21.1204 14.1234L16.1202 19.1184C14.2299 21.0068 11 19.668 11 16.996V7.00601ZM14.7067 6.29854C14.0766 5.66908 13 6.11536 13 7.00601V16.996C13 17.8866 14.0766 18.3329 14.7067 17.7035L19.7069 12.7085C20.0979 12.3179 20.0979 11.6841 19.7069 11.2935L14.7067 6.29854Z" />
  </svg>
</template>

<script>
export default {
  name: 'PlayNext',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
